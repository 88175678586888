<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Balance the chemical reaction shown below. Be sure to include values of 1 for any species
        that only have 1 equivalent involved in the reaction (i.e. empty inputs DO NOT correspond to
        the value "1"). Use integers for ALL coefficients.
      </p>

      <p class="mb-2">
        <chemical-latex :content="mol1" />

        <v-text-field
          v-model="inputs.o2"
          style="width: 40px"
          outlined
          dense
          class="d-inline-block"
        />
        <chemical-latex :content="mol2" />

        <v-text-field
          v-model="inputs.co2"
          style="width: 40px"
          outlined
          dense
          class="d-inline-block"
        />
        <chemical-latex :content="mol3" />

        <v-text-field
          v-model="inputs.h2o"
          style="width: 40px"
          outlined
          dense
          class="d-inline-block"
        />
        <chemical-latex :content="mol4" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question357',
  components: {
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        o2: null,
        co2: null,
        h2o: null,
      },
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    versionData() {
      switch (this.versionVariable.value) {
        case 1: // 1:5:3:4
          return {
            mol1Var: 'C3H8(g) +',
            mol2Var: 'O2(g) -> ',
            mol3Var: 'CO2(g) +',
            mol4Var: 'H2O(g)',
          };
        case 2: // 2:13:8:10
          return {
            mol1Var: '2 C4H10(g) +',
            mol2Var: 'O2(g) -> ',
            mol3Var: 'CO2(g) +',
            mol4Var: 'H2O(g)',
          };
        case 3: // 1:8:5:6
          return {
            mol1Var: 'C5H12(g) +',
            mol2Var: 'O2(g) -> ',
            mol3Var: 'CO2(g) +',
            mol4Var: 'H2O(g)',
          };
        case 4: // 2:19:12:14
          return {
            mol1Var: '2 C6H14(g) +',
            mol2Var: 'O2(g) -> ',
            mol3Var: 'CO2(g) +',
            mol4Var: 'H2O(g)',
          };
        case 5: // 1:11:7:8
          return {
            mol1Var: 'C7H16(g) +',
            mol2Var: 'O2(g) -> ',
            mol3Var: 'CO2(g) +',
            mol4Var: 'H2O(g)',
          };
        default:
          return {
            mol1Var: 'ERROR +',
            mol2Var: 'ERROR -> ',
            mol3Var: 'ERROR +',
            mol4Var: 'ERROR',
          };
      }
    },
    mol1() {
      return this.versionData.mol1Var;
    },
    mol2() {
      return this.versionData.mol2Var;
    },
    mol3() {
      return this.versionData.mol3Var;
    },
    mol4() {
      return this.versionData.mol4Var;
    },
  },
};
</script>
